
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import SafetyVerification from "./SafetyVerification.vue";
import ProfilePhoto from "./ProfilePhoto.vue";
import { namespace } from "vuex-class";

const UserModule = namespace("user");

@Component({
  name: "UserinfoModal",
  components: { SafetyVerification, ProfilePhoto },
  filters: {
    //局部过滤器
  },
})
export default class UserinfoModal extends Vue {
  @UserModule.State("email") email!: string;
  @UserModule.State("phone") phone!: string;

  public wrapperCol: any = { span: 16 };
  public userFormData: any = {};
  // 验证规则
  public rules: any = {
    value: [{ required: true, message: "此项必填", trigger: "blur" }],
    code: [{ required: true, message: "此项必填", trigger: "blur" }],
    oldPassword: [{ required: true, message: "此项必填", trigger: "blur" }],
    password: [{ required: true, message: "此项必填", trigger: "blur" }],
    affirmPassword: [{ required: true, message: "此项必填", trigger: "blur" }],
  };
  public step: number = 1;
  public verifyType: string = "";

  // 点击确认
  handleOk(): void {
    this.ruleForm.validate((valid: boolean) => {
      if (valid) {
        // 如果存在下一步
        if (this.okText === "下一步") {
          // console.log(this.userFormData.code, this.modalType, this.verifyType);
          let params = {
            code: this.userFormData.code,
            loginId:
              this.verifyType === ""
                ? this.modalType.params.title === "手机号"
                  ? this.phone
                  : this.email
                : this.verifyType === "手机号"
                ? this.phone
                : this.email,
            modelNumber:
              this.modalType.params.title === "手机号" ? "007" : "006",
          };
          // console.log(params);
          this.$apis.userAuth(params).then((res: any) => {
            if (res.code === "200") {
              this.userFormData = {};
              this.step = 2;
            }
          });
        } else {
          this.$emit("ok", this.modalType, this.userFormData, this.verifyType);
        }
      }
    });
  }

  //  获取验证码
  handleGetCode(type: string, bind: boolean, reset: () => void): void {
    // console.log(type, bind, this.modalType);
    let params;
    let modelNumber;
    if (bind) {
      if (!this.userFormData.value) {
        this.$message.warning("绑定的号码不能为空");
        reset();
        return;
      }
      if (
        this.modalType.params.value === "" &&
        this.modalType.type === "绑定"
      ) {
        modelNumber = "004";
        console.log("手机号绑定");
      } else if (this.modalType.params.title === "邮箱") {
        modelNumber = "006";
        console.log("邮箱更换");
      } else if (this.modalType.params.title === "手机号") {
        modelNumber = "007";
        console.log("手机号更换");
      }
      params = {
        loginId: this.userFormData.value,
        modelNumber: modelNumber,
      };
    } else {
      if (this.modalType.type === "解绑") {
        modelNumber = "005";
        console.log("手机号解绑");
      } else {
        modelNumber = this.modalType.params.title === "手机号" ? "007" : "006";
        console.log("手机号邮箱验证");
      }
      params = {
        loginId: type === "手机号" ? this.phone : this.email,
        modelNumber: modelNumber,
      };
    }
    this.$apis.getEmailCode(params);
  }

  // 点击关闭
  handleCancel(): void {
    this.$emit("cancel");
  }
  // 获取头像图片
  getImage(value: any) {
    this.userFormData.value = value;
  }
  // 改变验证方式
  changeVerifyType(value: string) {
    this.verifyType = value;
    this.userFormData = {};
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ required: true })
  // 表单类型和数据
  modalType!: any;
  @Prop({ default: false })
  visible!: boolean;
  /* 计算属性 */
  // 弹出框标题
  get title(): string {
    if (this.modalType.params.title === "头像") {
      return "请选择图片上传";
    } else if (this.modalType.params.title === "昵称") {
      return "修改名称";
    } else if (this.modalType.params.title === "邮箱") {
      return this.modalType.params.value === ""
        ? "绑定邮箱"
        : this.step === 1
        ? "安全验证"
        : "绑定新邮箱";
    } else if (this.modalType.params.title === "手机号") {
      return this.modalType.params.value === ""
        ? "绑定手机号码"
        : this.step === 1
        ? "安全验证"
        : "绑定新手机号码";
    } else if (this.modalType.params.title === "密码") {
      return "修改密码";
    } else {
      return "编辑" + this.modalType.params.title;
    }
  }
  // 是否存在下一步
  get okText(): string {
    if (
      (this.modalType.params.title === "邮箱" ||
        this.modalType.params.title === "手机号") &&
      this.modalType.type === "绑定" &&
      this.modalType.params.value !== "" &&
      this.step === 1
    ) {
      return "下一步";
    } else {
      return "确认";
    }
  }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  //创建前钩子函数
  created(): void {
    // console.log(this.modalType);
  }
}
