
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "UserinfoList",
  components: {},
  filters: {
    //局部过滤器
    bind(value: string, data: any) {
      if (value === "绑定") {
        return data.value === "" ? "绑定" : "更改";
      } else {
        return value;
      }
    },
  },
})
export default class UserinfoList extends Vue {
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ required: true })
  userinfoData!: {
    title: string;
    value: string | number;
    action: string[];
    [key: string]: any;
  };
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
