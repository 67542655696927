
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import Cropper from "cropperjs";
import { namespace } from "vuex-class";

const defaultAvatarImage = require("@/assets/images/avatarImage.png");

const UserModule = namespace("user");
@Component({
  name: "ProfilePhoto",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ProfilePhoto extends Vue {
  @UserModule.State("name") name!: string;
  @UserModule.State("avatarImage") avatarImage!: string;

  public headerImage: string = "";
  public picValue: string = "";
  public cropper: any = null;
  public croppable: boolean = false;
  public panel: boolean = false;
  public url: string = "";

  getObjectURL(file: any) {
    var url = null;
    if ((window as any).createObjectURL != undefined) {
      // basic
      url = (window as any).createObjectURL(file);
    } else if (window.URL != undefined) {
      // mozilla(firefox)
      url = window.URL.createObjectURL(file);
    } else if (window.webkitURL != undefined) {
      // webkit or chrome
      url = window.webkitURL.createObjectURL(file);
    }
    return url;
  }
  change(e: any) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    if (!files[0].type.includes("image")) {
      this.$message.warning("文件格式有误，请上传jpg或png格式!");
      e.target.value = "";
      return false;
    } else if (files[0].size > 1048576) {
      this.$message.warning("上传图片过大,请选择小于1M大小的图片");
      e.target.value = "";
      return false;
    }
    this.panel = true;
    this.picValue = files[0];
    this.url = this.getObjectURL(this.picValue);
    //每次替换图片要重新得到新的url
    if (this.cropper) {
      this.cropper.replace(this.url);
    }
    this.panel = true;
  }
  crop() {
    this.panel = false;
    var that = this;
    var croppedCanvas;
    var roundedCanvas;
    if (!this.croppable) {
      return;
    }
    // Crop
    croppedCanvas = this.cropper.getCroppedCanvas();
    // Round
    roundedCanvas = this.getRoundedCanvas(croppedCanvas);
    this.headerImage = roundedCanvas.toDataURL();
    roundedCanvas.toBlob(function (e: any) {
      e.name = that.name + ".jpeg";
      that.postImg(e);
    }, "image/jpeg");
  }
  close() {
    this.panel = false;
    this.fileHTML.value = "";
  }
  getRoundedCanvas(sourceCanvas: any) {
    var canvas: any = document.createElement("canvas");
    var context: any = canvas.getContext("2d");
    var width: number = sourceCanvas.width;
    var height: number = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;

    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    // context.arc(
    //   width / 2,
    //   height / 2,
    //   Math.min(width, height) / 2,
    //   0,
    //   2 * Math.PI,
    //   true
    // );
    context.fill();
    return canvas;
  }
  postImg(value: any) {
    this.$emit("cropImage", value);
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("image")
  imageHTML: any;
  @Ref("file")
  fileHTML: any;
  //创建前钩子函数
  created(): void {
    this.headerImage = this.avatarImage || defaultAvatarImage;
  }
  mounted(): void {
    var self = this;
    this.cropper = new Cropper(this.imageHTML, {
      aspectRatio: 1,
      viewMode: 1,
      background: false,
      zoomable: false,
      ready: function () {
        self.croppable = true;
      },
    });
  }
}
