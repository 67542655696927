var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-modal',{staticClass:"userinfo-modal",attrs:{"title":_vm.title,"visible":_vm.visible,"width":368,"okText":_vm.okText,"maskClosable":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.userFormData,"rules":_vm.rules}},[(_vm.modalType.params.title === '头像')?[_c('ProfilePhoto',{on:{"cropImage":_vm.getImage}})]:((_vm.verifyType === '邮箱' || _vm.verifyType === '手机号') && _vm.step !== 2)?[_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"code":_vm.userFormData.code,"type":_vm.verifyType},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"usePhone":function($event){return _vm.changeVerifyType('手机号')},"useEmail":function($event){return _vm.changeVerifyType('邮箱')},"getCode":_vm.handleGetCode}})],1)]:(_vm.modalType.params.title === '昵称')?[_c('a-form-model-item',{attrs:{"prop":"value"}},[_c('a-input',{attrs:{"placeholder":"请输入用户名"},model:{value:(_vm.userFormData.value),callback:function ($$v) {_vm.$set(_vm.userFormData, "value", $$v)},expression:"userFormData.value"}})],1)]:(
        _vm.modalType.params.title === '邮箱' && _vm.modalType.params.value === ''
      )?[_c('a-form-model-item',{attrs:{"prop":"value"}},[_c('a-input',{attrs:{"placeholder":"请输入绑定的邮箱"},model:{value:(_vm.userFormData.value),callback:function ($$v) {_vm.$set(_vm.userFormData, "value", $$v)},expression:"userFormData.value"}})],1),_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"bind":true,"code":_vm.userFormData.code},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"getCode":_vm.handleGetCode}})],1)]:(
        _vm.modalType.params.title === '邮箱' && _vm.modalType.params.value !== ''
      )?[(_vm.step === 1)?[_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"code":_vm.userFormData.code,"type":_vm.modalType.params.title},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"usePhone":function($event){return _vm.changeVerifyType('手机号')},"useEmail":function($event){return _vm.changeVerifyType('邮箱')},"getCode":_vm.handleGetCode}})],1)]:[_c('a-form-model-item',{attrs:{"prop":"value"}},[_c('a-input',{attrs:{"placeholder":"请输入绑定的邮箱"},model:{value:(_vm.userFormData.value),callback:function ($$v) {_vm.$set(_vm.userFormData, "value", $$v)},expression:"userFormData.value"}})],1),_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"bind":true,"code":_vm.userFormData.code},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"getCode":_vm.handleGetCode}})],1)]]:(
        _vm.modalType.params.title === '手机号' && _vm.modalType.params.value === ''
      )?[_c('a-form-model-item',{attrs:{"prop":"value"}},[_c('a-input',{attrs:{"placeholder":"请输入绑定的手机号"},model:{value:(_vm.userFormData.value),callback:function ($$v) {_vm.$set(_vm.userFormData, "value", $$v)},expression:"userFormData.value"}})],1),_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"bind":true,"code":_vm.userFormData.code},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"getCode":_vm.handleGetCode}})],1)]:(
        _vm.modalType.params.title === '手机号' && _vm.modalType.params.value !== ''
      )?[(_vm.step === 1)?[_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"code":_vm.userFormData.code,"type":_vm.modalType.params.title},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"usePhone":function($event){return _vm.changeVerifyType('手机号')},"useEmail":function($event){return _vm.changeVerifyType('邮箱')},"getCode":_vm.handleGetCode}})],1)]:[_c('a-form-model-item',{attrs:{"prop":"value"}},[_c('a-input',{attrs:{"placeholder":"请输入绑定的手机号码"},model:{value:(_vm.userFormData.value),callback:function ($$v) {_vm.$set(_vm.userFormData, "value", $$v)},expression:"userFormData.value"}})],1),_c('a-form-model-item',{attrs:{"prop":"code"}},[_c('SafetyVerification',{attrs:{"bind":true,"code":_vm.userFormData.code},on:{"update:code":function($event){return _vm.$set(_vm.userFormData, "code", $event)},"getCode":_vm.handleGetCode}})],1)]]:(_vm.modalType.params.title === '密码')?[_c('a-form-model-item',{attrs:{"prop":"oldPassword"}},[_c('a-input-password',{attrs:{"placeholder":"请输入旧密码"},model:{value:(_vm.userFormData.oldPassword),callback:function ($$v) {_vm.$set(_vm.userFormData, "oldPassword", $$v)},expression:"userFormData.oldPassword"}})],1),_c('a-form-model-item',{attrs:{"prop":"password"}},[_c('a-input-password',{attrs:{"placeholder":"请输入密码"},model:{value:(_vm.userFormData.password),callback:function ($$v) {_vm.$set(_vm.userFormData, "password", $$v)},expression:"userFormData.password"}})],1),_c('a-form-model-item',{attrs:{"prop":"affirmPassword"}},[_c('a-input-password',{attrs:{"placeholder":"请确认密码"},model:{value:(_vm.userFormData.affirmPassword),callback:function ($$v) {_vm.$set(_vm.userFormData, "affirmPassword", $$v)},expression:"userFormData.affirmPassword"}})],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }