
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
// 复用一下登录页面的验证码倒计时功能
import CountDown from "@/views/User/components/mixin/countDown";

const UserModule = namespace("user");
@Component({
  name: "SafetyVerification",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class SafetyVerification extends mixins(CountDown) {
  @UserModule.State("email") email!: string;
  @UserModule.State("phone") phone!: string;

  public tmpCode: string = "";

  // 获取验证码
  queryCode(): void {
    this.isQueryCode = true;
    this.againQueryTime();
    this.$emit("getCode", this.type, this.bind, this.closeTimer);
  }
  // 使用手机号
  usePhone(): void {
    this.closeTimer();
    this.$emit("usePhone");
  }
  // 使用邮箱
  useEmail(): void {
    this.closeTimer();
    this.$emit("useEmail");
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: "" })
  code!: string;
  @Prop({ default: "" })
  type!: string;
  @Prop({ default: false })
  bind!: boolean;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("tmpCode")
  tmpCodeChange(newValue: string) {
    this.$emit("update:code", newValue);
  }
  @Watch("code")
  codeChange(newValue: string) {
    this.tmpCode = newValue;
  }
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {
    this.tmpCode = this.code;
  }
}
