
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import UserinfoList from "./components/UserinfoList.vue";
import UserinfoModal from "./components/UserinfoModal.vue";
import { namespace } from "vuex-class";
import { parse } from "path";

const UserModule = namespace("user");
@Component({
  name: "Userinfo",
  components: { UserinfoList, UserinfoModal },
  filters: {
    //局部过滤器
  },
})
export default class Userinfo extends Vue {
  @UserModule.State("avatarImage") avatarImage!: string;
  @UserModule.State("name") name!: string;
  @UserModule.State("email") email!: string;
  @UserModule.State("phone") phone!: string;
  @UserModule.State("userId") userId!: string;

  // @UserModule.Mutation("SET_USER") setUser!: (payload: any) => void;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (value:string) => void;

  public userinfo: any[] = [
    {
      title: "头像",
      value: "",
      action: ["上传"],
    },
    {
      title: "昵称",
      value: "",
      action: ["编辑"],
    },
    {
      title: "邮箱",
      value: "",
      action: ["绑定"],
      // action: ["绑定", "解绑"],
    },
    {
      title: "手机号",
      value: "",
      action: ["绑定", "解绑"],
    },
    {
      title: "密码",
      value: "********",
      action: ["更改"],
    },
    // {
    //   title: "账号类型",
    //   value: "免费",
    //   action: [],
    // },
  ];
  public UserinfoModalShow: boolean = false;
  public modalType: any = {};

  // 用户信息操作
  handleClick(
    type: string,
    user: {
      title: string;
      value: string | number;
      action?: string;
      [key: string]: any;
    }
  ) {
    this.modalType = {
      type: type,
      params: user,
    };
    this.UserinfoModalShow = true;
  }

  // 弹窗关闭
  cancel(): void {
    this.UserinfoModalShow = false;
  }

  // 点击提交或下一步
  handleOk(eventData: any, params: any, verifyType: string): void {
    // console.log(eventData, params);
    if (eventData.params.title === "头像") {
      if (!params.value) {
        this.$message.warning("请选择图片上传");
        return;
      }
      let apiParams = new FormData();
      let header = {
        fileName: this.userId + ".jpeg",
      };
      apiParams.append("multipartFile", params.value);
      this.$apis.uploadProfilePhoto(apiParams, header).then((res: any) => {
        if (res.code === "200") {
          this.$message.success("上传成功");
          this.getUserinfo('avatarImage');
          this.UserinfoModalShow = false;
        }
      });
    } else if (eventData.params.title === "昵称") {
      let apiParams = {
        id: this.userId,
        nickname: params.value,
      };
      this.$apis.userEditName(apiParams).then((res: any) => {
        if (res.code === "200") {
          this.$message.success("修改成功");
          this.setUserStorage("userNickname", params.value);
          this.UserinfoModalShow = false;
        }
      });
    } else if (eventData.params.title === "邮箱") {
      this.$apis
        .resetEmail({
          code: params.code,
          loginId: params.value,
          modelNumber: "006",
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.$message.success("修改成功");
            this.setUserStorage("email", params.value);
            this.UserinfoModalShow = false;
          }
        });
    } else if (eventData.params.title === "手机号") {
      if (eventData.params.value === "") {
        this.$apis
          .bindPhone({
            code: params.code,
            loginId: params.value,
          })
          .then((res: any) => {
            if (res.code === "200") {
              this.$message.success("绑定成功");
              this.setUserStorage("phone", params.value);
              this.UserinfoModalShow = false;
            }
          });
      } else {
        if (eventData.type === "解绑") {
          this.$apis
            .unbindPhone({
              code: params.code,
              loginId:
                verifyType === ""
                  ? eventData.params.title === "手机号"
                    ? this.phone
                    : this.email
                  : verifyType === "手机号"
                  ? this.phone
                  : this.email,
              modelNumber: "005",
            })
            .then((res: any) => {
              if (res.code === "200") {
                this.$message.success("解绑成功");
                this.setUserStorage("phone", "");
                this.UserinfoModalShow = false;
              }
            });
        } else {
          this.$apis
            .resetPhone({
              code: params.code,
              loginId: params.value,
              modelNumber: "007",
            })
            .then((res: any) => {
              if (res.code === "200") {
                this.$message.success("修改成功");
                this.setUserStorage("phone", params.value);
                this.UserinfoModalShow = false;
              }
            });
        }
      }
    } else if (eventData.params.title === "密码") {
      // console.log(params);
      if (params.password !== params.affirmPassword) {
        this.$message.warning("两次密码不一致");
        return;
      } else {
        let apiParams = {
          id: this.userId,
          oldPassword: params.oldPassword,
          password: params.password,
        };
        this.$apis.modifyPassword(apiParams).then((res: any) => {
          if (res.code === "200") {
            this.$message.success("修改成功");
            this.UserinfoModalShow = false;
          }
        });
      }
    }
  }

  // 本地存储同步
  setUserStorage(key: string, value: any): void {
    let userStorage: any = sessionStorage.getItem("X-Access-Token");
    if (userStorage) {
      try {
        userStorage = JSON.parse(userStorage);
        userStorage[key] = value;
        // this.setUser(userStorage);
        sessionStorage.setItem("X-Access-Token", JSON.stringify(userStorage));
      } catch (error) {
        console.error(error);
      }
    }
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  get userMsg(): any {
    return {
      avatarImage: this.avatarImage,
      name: this.name,
      email: this.email,
      phone: this.phone,
    };
  }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("userMsg", { immediate: true })
  userMsgChange(newValue: any) {
    this.userinfo.forEach((item) => {
      if (item.title === "头像") {
        item.value = newValue.avatarImage;
      } else if (item.title === "昵称") {
        item.value = newValue.name;
      } else if (item.title === "邮箱") {
        item.value = newValue.email;
      } else if (item.title === "手机号") {
        item.value = newValue.phone;
      }
    });
  }
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
